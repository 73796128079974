import React from "react"
import { graphql } from "gatsby"
import * as S from "./styles/__events.styles"
import { EventCardWLink, Banner, Layout, HeadingCard } from "../components"

const Events = (data) => {
  const events = data.data.allContentfulEvent.nodes
  const pageDetails = data.data.contentfulWebsiteDetails.eventsPage

  return (
    <Layout>
      <S.Grid>
        <HeadingCard
          heading={pageDetails?.heading}
          description={pageDetails?.description.description}
        />
        {pageDetails?.bannerImage && (
          <Banner image={pageDetails?.bannerImage} />
        )}
        {events.map((event, i) => {
          return (
            <EventCardWLink
              key={i}
              link={`/events/${event.slug}`}
              title={event.title}
              desc={event.body?.body}
              location={event.location}
              date={event.date}
            />
          )
        })}
      </S.Grid>
    </Layout>
  )
}

export default Events

export const query = graphql`
  query {
    contentfulWebsiteDetails {
      eventsPage {
        heading
        description {
          description
        }
        bannerImage {
          description
          fluid(maxWidth: 1312, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    allContentfulEvent {
      nodes {
        slug
        date
        location
        price
        title
        body {
          body
        }
      }
    }
  }
`
