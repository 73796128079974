import media from "styled-media-query"
import styled from "styled-components"
import { EventCardWLink, Banner, HeadingCard } from "../../components"


// medium is 768px
// 414px is the largest mobile screen

export const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  ${HeadingCard}, ${EventCardWLink} {
    border-bottom: ${props => props.theme.borderStyle};
  }

  ${Banner} {
    height: 320px;
    img {
      border-bottom: ${props => props.theme.borderStyle};
    }
  }
  ${HeadingCard} {
    padding: 2.5rem 1rem;
    background: ${props => props.theme.ui01};
  }
  ${EventCardWLink} {
    a {
      padding: 1.5rem 1rem;
    }
  }
  ${media.greaterThan("414px")`
    ${EventCardWLink} {
      h2, p {
        max-width: 75%;
      }
    }
    ${HeadingCard} {
      > * {
        max-width: 70%;
      }
    }
  `}
  ${media.between("medium","1055px")`
      ${EventCardWLink} {
        h2, p {
          max-width: max-content;
        }
      }
  `}
  ${media.greaterThan("medium")`
    grid-template-columns: 1fr 1fr;
    ${HeadingCard}, ${Banner} {
      grid-column: 1 / -1;
    }
    ${HeadingCard} {
      padding: 4rem 0rem;
      > * {
        max-width: 38%;
      }
    }
   ${EventCardWLink} {
      a {
        padding: 2rem 2rem;
      }
      &:nth-child(odd) {
          border-right: ${props => props.theme.borderStyle};
      }
      :nth-last-child(2):nth-child(2n) {
        border-bottom: ${props => props.theme.borderStyle};
      }
  `}
  ${media.between("medium","1055px" )`
    ${EventCardWLink} {
      padding-top: 100%;
      height: 0;
      position: relative;
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
    }
  `}
`
